export const DESKTOP_HEADER_NAV_HEIGHT = 82
export const MOBILE_FOOTER_HEIGHT = 72
export const MOBILE_HEADER_HEIGHT = 42

export const PAGE_WIDTH = 1300
export const PAGE_FULL_WIDTH = 1800

export const HEADER_CARD_HEIGHT = [180, 200]

export const VAULTS_CHART_HEIGHT = [120, 250]
export const VAULTS_INDEX_CHART_HEIGHT = [120, 250]

export const TRADE_CARD_MIN_WIDTH = 360
export const TRADE_CARD_MIN_HEIGHT = 380

export const TRADE_CHAIN_STAT_COL_WIDTH = 90
export const TRADE_CHAIN_PRICE_COL_WIDTH = 110
export const TRADE_CHAIN_STRIKE_COL_WIDTH = 100

export const TRADE_SPOT_LINE_CHART_HEIGHT = [120, 140]
export const TRADE_SPOT_CANDLE_CHART_HEIGHT = [160, 280]
export const ADMIN_TRANSACTIONS_CARD_WIDTH = 560

export const VOTE_PROPOSALS_CARD_GRID_COLUMN_TEMPLATE = ['1fr 2fr 1fr', '1fr 5fr 1fr']
export const EARN_VAULTS_CARD_GRID_COLUMN_TEMPLATE = ['1fr 36px', '1.5fr 1fr 1fr 1fr 1fr 36px']
export const EARN_REFERRALS_CARD_GRID_COLUMN_TEMPLATE = ['1fr 36px', '1.5fr 1fr 1fr 1fr 36px']
